<template>
    <b-container>
        <b-row>
            <b-col>
                <b-card class="mt-5">
                    <h5 class="mb-4">Accordion</h5>
                    <div class="accordion" role="tablist">
                        <b-card no-body class="mb-1">
                          <b-card-header header-tag="header" class="p-0" role="tab">
                              <b-button block v-b-toggle.accordion-1 variant="light" class="w-100 text-start">Accordion 1</b-button>
                          </b-card-header>
                          <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                              <b-card-body>
                              <b-card-text>I start opened because <code>visible</code> is <code>true</code></b-card-text>
                              <b-card-text>{{ text }}</b-card-text>
                              </b-card-body>
                          </b-collapse>
                        </b-card>

                        <b-card no-body class="mb-1">
                          <b-card-header header-tag="header" class="p-0" role="tab">
                              <b-button block v-b-toggle.accordion-2 variant="light" class="w-100 text-start">Accordion 2</b-button>
                          </b-card-header>
                          <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                              <b-card-body>
                              <b-card-text>{{ text }}</b-card-text>
                              </b-card-body>
                          </b-collapse>
                        </b-card>

                        <b-card no-body class="mb-1">
                          <b-card-header header-tag="header" class="p-0" role="tab">
                              <b-button block v-b-toggle.accordion-3 variant="light" class="w-100 text-start">Accordion 3</b-button>
                          </b-card-header>
                          <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                              <b-card-body>
                              <b-card-text>{{ text }}</b-card-text>
                              </b-card-body>
                          </b-collapse>
                        </b-card>
                    </div>
                </b-card>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
export default {
  data () {
    return {
      text: `
        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
        richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor
        brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon
        tempor, sunt aliqua put a bird on it squid single-origin coffee nulla
        assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore
        wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher
        vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic
        synth nesciunt you probably haven't heard of them accusamus labore VHS.
      `
    }
  }
}
</script>
